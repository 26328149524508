body,
html {
	font-family: 'Roboto', sans-serif;
	height: 100%;
}

#root {
	overflow-x: hidden;
	min-height: 100vh;
}

body {
	background: #fff !important;
	overflow-x: hidden;
	/* overflow-y: hidden; */
}

*:focus {
	outline: none !important;
}

div#signin-page,
div#forgot-page,
div#reset-page {
	padding-top: 100px;
}

div#signup-page {
	padding-top: 20px;
}

nav#dashboard-navbar {
	margin-bottom: 30px;
}

div.credit-types {
	margin-top: 30px;
}

div.credit-types > div {
	opacity: 0.7;
}

div.credit-types > div:hover {
	opacity: 1;
	cursor: pointer;
}

div#credit-request-form {
	margin-top: 30px;
}

.request-section-label {
	font-size: 18px;
	margin-bottom: 10px;
	color: #3f51b5;
}

.request-section-label-marital {
	font-size: 18px;
	margin-top: 15px;
	margin-bottom: -10px;
	color: #3f51b5;
}

.request-section-label-financial {
	font-size: 18px;
	margin-bottom: 20px;
	color: #3f51b5;
}

.request-section-label-icon {
	padding-right: 5px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* disable firefox inner border for clickable items */

*::-moz-focus-inner {
	border: 0;
}

.std-input {
	/* standard input field look */
	border: 1px solid #7b6d8d;
	font-size: 12px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	color: #343434 !important;
	border-radius: 2px !important;
}

.shadow {
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.std-input-select {
	/* custom dropdown */
	-webkit-appearance: none;
	-webkit-border-radius: 2px;
	-moz-appearance: window;
	background-image: url('https://i.imgur.com/L83AiBf.png');
	background-repeat: no-repeat;
	background-position: 98% center;
}

.std-input:active,
.std-input:focus,
.std-input:hover {
	border: 1px solid #7b6d8d !important;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	border-radius: 2px !important;
}

.std-input-btn {
	border: none;
	border-radius: 4px;
	/* background-image: linear-gradient(to right, rgb(226, 211, 89), rgb(219, 198, 96)); */
	background: #7b6d8d;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	color: #f8fdfd;
}

.std-input-btn:hover,
.std-input-btn:active,
.std-input-btn:focus {
	color: #f8fdfd;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	background: #7b6d8d;
}

div#credit-selector-container {
	border: 1px solid rgba(0, 0, 0, 0.1);
	padding: 15px;
	border-radius: 6px;
}

div.credit-selector-header {
	color: #343434;
	font-size: 16px;
	margin-bottom: -5px;
}

div.credit-selector-type {
	color: #3f51b5;
}

/* testout for range selectors */

input[type='range'] {
	-webkit-appearance: none;
	margin: 18px 0;
	width: 100%;
}

input[type='range']:focus {
	outline: none;
}

input[type='range']::-webkit-slider-runnable-track {
	width: 100%;
	height: 3px;
	cursor: pointer;
	animate: 0.2s;
	background: grey;
	border-radius: 1.3px;
}

input[type='range']::-webkit-slider-thumb {
	box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
	/* border: 1px solid #000000; */
	height: 18px;
	width: 18px;
	border-radius: 100%;
	background: rgb(89, 60, 144);
	cursor: pointer;
	-webkit-appearance: none;
	margin-top: -7px;
}

input[type='range']:focus::-webkit-slider-runnable-track {
	/* background: rgb(89, 60, 144); */
}

input[type='range']::-moz-range-track {
	width: 100%;
	height: 3px;
	cursor: pointer;
	animate: 0.2s;
	box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
	background: rgb(89, 60, 144);
	border-radius: 1.3px;
	border: 0.2px solid #010101;
}

input[type='range']::-moz-range-thumb {
	box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
	height: 18px;
	width: 18px;
	border-radius: 50%;
	background: rgb(89, 60, 144);
	cursor: pointer;
}

input[type='range']::-ms-track {
	width: 100%;
	height: 3px;
	cursor: pointer;
	animate: 0.2s;
	background: transparent;
	border-color: transparent;
	border-width: 16px 0;
	color: transparent;
}

input[type='range']::-ms-fill-lower {
	background: #2a6495;
	border: 0.2px solid #010101;
	border-radius: 2.6px;
	box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

input[type='range']::-ms-fill-upper {
	background: #3071a9;
	border: 0.2px solid #010101;
	border-radius: 2.6px;
	box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

input[type='range']::-ms-thumb {
	box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
	height: 36px;
	width: 16px;
	border-radius: 50%;
	background: rgb(89, 60, 144);
	cursor: pointer;
}

input[type='range']:focus::-ms-fill-lower {
	background: #3071a9;
}

input[type='range']:focus::-ms-fill-upper {
	background: #367ebd;
}

.range-input-label {
	font-size: 20px;
	border-radius: 4px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	border-radius: 10px;
	border-bottom-right-radius: 1px;
	padding-top: 2px;
	padding-bottom: 2px;
}

.credit-selector-pricing-price {
	font-size: 34px;
	color: #7b6d8d;
	font-weight: bold;
}

.credit-selector-pricing-currency {
	font-size: 15px;
	line-height: 16px;
	padding-top: 9px;
	margin-left: -20px;
	color: #7b6d8d;
	font-weight: bold;
}

.credit-selector-pricing-container {
	margin-top: 25px;
	margin-bottom: -5px;
}

.credit-selector-pricing {
	background: #e5e2e9;
	padding-right: 15px;
	border-radius: 4px;
}

/* no gutters */

.no-gutters {
	margin-right: 0;
	margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*='col-'] {
	padding-right: 0;
	padding-left: 0;
}

#sidenav {
	/* background-image: linear-gradient(to bottom, #000000, #0f0f0f, #191919, #212121, #2a2a2a); */
	background: #fff;
	left: 0;
	height: 100vh;
	width: 250px;
	max-width: 250px;
	position: fixed;
	z-index: 5;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	overflow-y: auto;
}

#navbar {
	position: relative;
	width: 100vw;
	background: #2c3156;
	margin-left: -250px;
	height: 250px;
	overflow: hidden;
	color: white !important;
}

#main-app-content {
	position: relative;
	margin-left: 250px;
}

img#main-sidebar-logo {
	padding: 0 20px;
	background: #fff;
}

#sidenav-content {
	height: 100%;
}

#sidenav-content > .img-wrapper {
	padding-top: 55px;
	padding-bottom: 40px;
}

.sidenav-menu {
	margin-top: 20px;
	font-size: 11px;
	line-height: 13px;
	color: black;
}

.sidenav-item {
	padding-top: 15px;
	padding-bottom: 15px;
	display: block;
	color: black !important;
	font-size: 15px;
	font-weight: 400;
	padding-left: 20px;
	/* make unselectable */
	user-select: none;
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-o-user-select: none;
}

#new-request-circle {
	color: #0080ff;
	font-size: 45px;
	position: absolute;
	top: -15px;
	right: -15px;
	z-index: 1000;
}

#new-request-button {
	background: white;
}

#new-request-button:hover {
	/* box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); */
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.sidenav-item a {
	color: black;
}

.sidenav-item:hover {
	background: #f2f2f2;
}

.sidenav-item a:hover {
	text-decoration: none;
}

.sidenav-item:hover {
	text-decoration: none !important;
	cursor: pointer;
	color: #f8fdfd;
}

.new-request-link:hover {
	text-decoration: none;
}

.sidenav-item-active {
	color: black !important;
	background: #f2f2f2 !important;
}

.mobile-requests-nav-active,
.mobile-requests-nav-item {
	color: black;
	text-decoration: none;
}

.mobile-requests-nav-item:hover {
	color: black;
	text-decoration: none;
}

.mobile-requests-nav-active > li {
	background: #f2f2f2 !important;
}

.navbar-item-active {
	opacity: 0.8;
}

.sidenav-logo {
	font-size: 32px;
	display: block;
	padding-bottom: 5px;
}

#requests-container {
	margin-top: 5px;
}

span.request-status {
	display: inline-block;
	opacity: 1;
	color: white !important;
	padding: 4px 10px;
	border-radius: 10px;
}

.request-status-unconfirmed {
	background: #d32f2f !important;
}

.request-status-waiting-call {
	background: #ffa000 !important;
}

.request-status-waiting-offer {
	background: #007bff !important;
}

.request-status-completed {
	background: #43a047 !important;
}

.request-header-title {
	text-transform: uppercase;
	font-size: 11px;
	font-weight: bold;
	color: #909294;
	line-height: 15px;
	margin-bottom: -5px;
}

div.request-text {
	text-transform: uppercase;
	font-size: 11px;
	font-weight: bold;
	color: #4d5154;
	line-height: 12px;
}

.additional-info-toggle {
	color: #909294;
}

.std-seperator-thick {
	opacity: 0.1;
	border: 0.5px solid black;
}

.switch-wrapper {
	position: relative;
}

.switch-wrapper > div {
	position: absolute;
	width: 100%;
}

.additional-info-toggle:hover {
	cursor: pointer;
}

.slide-in-left {
	-webkit-animation: slide-in-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: slide-in-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-3-6 13:16:28
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */

@-webkit-keyframes slide-in-left {
	0% {
		-webkit-transform: translateX(-1000px);
		transform: translateX(-1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes slide-in-left {
	0% {
		-webkit-transform: translateX(-1000px);
		transform: translateX(-1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}

.slide-in-bottom {
	-webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-3-7 11:57:21
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */

@-webkit-keyframes slide-in-bottom {
	0% {
		-webkit-transform: translateY(1000px);
		transform: translateY(1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes slide-in-bottom {
	0% {
		-webkit-transform: translateY(1000px);
		transform: translateY(1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

.alert-container {
	position: fixed;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 15px;
	padding-right: 40px;
	border-radius: 4px;
	top: 1%;
	right: 1%;
	color: white !important;
	z-index: 1000;
	font-size: 13px;
}

.alert-success {
	background-color: #43a047 !important;
}

#request-credit-action-accept {
	color: #43a047 !important;
}

.alert-danger {
	background-color: #d32f2f !important;
}

.alert-warning {
	background-color: #ffa000 !important;
}

.slide-out-bottom {
	-webkit-animation: slide-out-bottom 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
	animation: slide-out-bottom 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-3-7 15:39:21
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-bottom
 * ----------------------------------------
 */

@-webkit-keyframes slide-out-bottom {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(1000px);
		transform: translateY(1000px);
		opacity: 0;
	}
}

@keyframes slide-out-bottom {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(1000px);
		transform: translateY(1000px);
		opacity: 0;
	}
}

/* LOADING RING */

.lds-dual-ring {
	display: inline-block;
	width: 64px;
	height: 64px;
}

.lds-dual-ring:after {
	content: ' ';
	display: block;
	width: 46px;
	height: 46px;
	margin: 1px;
	border-radius: 50%;
	border: 5px solid white;
	border-color: white transparent white transparent;
	animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.fade-in {
	-webkit-animation: fade-in 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
	animation: fade-in 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-3-8 14:7:58
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */

@-webkit-keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.credit-company-request-options {
	margin-top: -4px;
	margin-bottom: -5px;
	width: 50px;
}

.credit-company-request-option {
	font-size: 13px !important;
	padding: 0 !important;
}

.centered-popup {
	position: absolute;
	width: 40%;
	top: 40px;
	left: 30%;
	/* transform: translate(-50%) !important; */
	padding: 50px 25px;
	background: #f7f7f7;
	border: 4px;
	z-index: 1000;
}

.popup-close {
	font-size: 24px;
	position: absolute;
	right: 15px !important;
	top: 0px !important;
}

.popup-close:hover {
	cursor: pointer;
}

.scale-in-top {
	-webkit-animation: scale-in-top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: scale-in-top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.centered-popup-title {
	margin-bottom: 20px;
	font-size: 20px;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-3-8 18:10:37
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-top
 * ----------------------------------------
 */

@-webkit-keyframes scale-in-top {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
		-webkit-transform-origin: 50% 0%;
		transform-origin: 50% 0%;
		opacity: 1;
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: 50% 0%;
		transform-origin: 50% 0%;
		opacity: 1;
	}
}

@keyframes scale-in-top {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
		-webkit-transform-origin: 50% 0%;
		transform-origin: 50% 0%;
		opacity: 1;
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: 50% 0%;
		transform-origin: 50% 0%;
		opacity: 1;
	}
}

.fade-out {
	-webkit-animation: fade-out 0.5s ease-out both;
	animation: fade-out 0.5s ease-out both;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-6-26 13:52:35
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-out
 * ----------------------------------------
 */
@-webkit-keyframes fade-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@keyframes fade-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.slide-in-top {
	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-3-12 18:55:28
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */

@-webkit-keyframes slide-in-top {
	0% {
		-webkit-transform: translateY(-1000px);
		transform: translateY(-1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes slide-in-top {
	0% {
		-webkit-transform: translateY(-1000px);
		transform: translateY(-1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

.credit-company-tag {
	padding: 4px;
	margin-bottom: 2px;
	color: white;
	border-radius: 2px;
}

.credit-company-decline:hover,
.credit-company-accept:hover {
	cursor: pointer;
}

.credit-company-decline {
	background: #d32f2f !important;
}

.credit-company-unconfirmed {
	/* background: #007bff !important; */
	background: #545454 !important;
}

.credit-company-accept {
	background: #43a047 !important;
}

.unselectable {
	-ms-user-select: none;
	user-select: none;
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-o-user-select: none;
}

.input-form-paper {
	padding: 20px;
}

img.auth-page-logo {
	height: 50px;
	width: auto;
	margin-bottom: 20px;
}

.request-id {
	text-transform: uppercase;
}

.request-credit-action-btn {
	padding: 4px !important;
}

#credit-request-table-header {
	opacity: 0.9;
	text-transform: uppercase !important;
}

/* #credit-request-table-header * {
  color: #343a3e !important;
} */

.unselectable {
	-moz-user-select: none !important;
	-webkit-user-select: none !important;
	user-select: none !important;
	pointer-events: none !important;
}

#navbar-items {
	list-style-type: none;
	padding-top: 10px;
}

li.navbar-item {
	display: inline;
	/* padding-right: 40px; */
	padding-left: 15px;
	padding-right: 15px;
	position: relative;
	z-index: 5;
}

li.navbar-item a {
	color: white !important;
}

li.navbar-item a:hover {
	text-decoration: none;
	opacity: 0.7;
}

#main-app-window {
	padding: 0px 30px;
	padding-bottom: 20px;
	position: relative;
	margin-top: -200px;
	z-index: 1;
}

h1.app-page-title {
	color: white;
	font-weight: 100;
	font-size: 40px;
	line-height: 50px;
}

#app-loader {
	padding: 100px;
	margin-top: 0px 40px;
	z-index: 10000000000;
	position: relative;
}

.credit-request-table-heading * {
	font-weight: 500 !important;
	text-transform: uppercase !important;
}

.credit-request-table-text * {
	/* font-weight: bold !important; */
	/* opacity: 0.8 !important; */
	color: rgba(0, 0, 0, 0.6) !important;
	font-size: 12px !important;
}

hr.request-table-seperator {
	margin-top: 0rem;
	margin-bottom: 1rem;
	border: 1px solid #e1e1e1;
}

#requests-container {
	max-height: 85vh;
	overflow-y: scroll;
}

/* #requests-container-content {
	min-width: 1100px;
}

#requests-container::-webkit-scrollbar {
	background-color: #fff;
	width: 16px;
} */

/* background of the scrollbar except button or resizer */

/* #requests-container::-webkit-scrollbar-track {
	background-color: #fff;
}

#requests-container::-webkit-scrollbar-track:hover {
	background-color: #f4f4f4;
} */

/* scrollbar itself */

/* #requests-container::-webkit-scrollbar-thumb {
	background-color: #babac0;
	border-radius: 16px;
	border: 5px solid #fff;
}

#requests-container::-webkit-scrollbar-thumb:hover {
	background-color: #a0a0a5;
	border: 4px solid #f4f4f4;
} */

/* set button(top and bottom of the scrollbar) */

/* #requests-container::-webkit-scrollbar-button {
	display: none;
} */

/* ::-webkit-scrollbar {
  width: 0px
}

::-webkit-scrollbar-track-piece {
  background-color: transparent;
  -webkit-border-radius: 6px;
} */

.credit-request-table-link:hover {
	text-decoration: none;
	transition: 1s;
	opacity: 1;
}

.credit-request-table-link:hover p {
	transition: 0.5s;
	color: black;
	opacity: 1;
}

.credit-request-table-paper {
	border-radius: 0px !important;
}

.credit-request-table-text {
	text-transform: uppercase;
}

#continue-with-beckmann-btn:hover,
#continue-with-frosch-btn:hover {
	transition: 1s;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

a.request-confirmation-button:hover {
	color: white !important;
}

#requests-container-wrapper {
	padding: 0 !important;
	padding-left: 5px !important;
}

.company-request-title,
.company-request-text {
	text-transform: uppercase;
}

.company-request-title {
	font-size: 11px !important;
	opacity: 0.8;
}

.request-info-modal-title {
	font-size: 11px !important;
	opacity: 0.8;
}

.company-request-text,
.company-request-text-normal {
	font-weight: 500;
	word-break: break-all;
}

#requests-container-search {
	color: white !important;
}

.requests-container-icon {
	margin-bottom: -10px;
	fill: white !important;
}

#filter-popper {
	z-index: 1 !important;
	margin-top: 10px;
}

#filter-popper-content {
	padding: 20px;
}

#reports-container {
	height: 70vh;
	overflow-y: scroll;
}

.auth-page {
	padding-bottom: 100px;
}

.csv-errored-icon {
	color: #d32f2f !important;
}

.csv-in-progress-icon {
	color: #007bff !important;
}

.csv-created-icon {
	color: #43a047 !important;
}

.profile-user-avatar {
	width: 65% !important;
	height: 65% !important;
}

.profile-user-edit {
	margin-top: -5px;
}

.profile-user-edit:hover {
	opacity: 0.5;
	cursor: pointer;
}

.new-company-add:hover {
	opacity: 0.5;
	cursor: pointer;
}

div#request-signup-page {
	padding-top: 50px;
}

/* background of the scrollbar except button or resizer */

#request-signup-container::-webkit-scrollbar-track {
	background-color: #fff;
}

#request-signup-container::-webkit-scrollbar-track:hover {
	background-color: #f4f4f4;
}

/* scrollbar itself */

#request-signup-container::-webkit-scrollbar-thumb {
	background-color: #babac0;
	border-radius: 16px;
	border: 5px solid #fff;
}

#request-signup-container::-webkit-scrollbar-thumb:hover {
	background-color: #a0a0a5;
	border: 4px solid #f4f4f4;
}

/* set button(top and bottom of the scrollbar) */

#request-signup-container::-webkit-scrollbar-button {
	display: none;
}

#mobile-navigation {
	display: none;
}

#mobile-settings-logout-btn {
	display: none;
}

#company-mobile-requests-navigation {
	display: none;
}

/* RESPONSIVENESS FOR MOBILE AND TABLETS */

#intercom-container > div > iframe {
	bottom: 20px !important;
}

/* SIDENAV -> MOBILE NAVIGATION */
@media only screen and (max-width: 769px) {
	#sidenav,
	#navbar-items,
	.mobile-hide {
		display: none;
	}
	/* 
	.credit-request-table-text * {
		font-size: 10px !important;
	} */

	.alert-container {
		bottom: 75px;
	}

	h1.app-page-title {
		font-size: 30px;
		line-height: 35px;
	}

	span.request-status {
		font-size: 10px !important;
	}

	#company-mobile-requests-navigation {
		display: inline;
	}

	#mobile-settings-logout-btn {
		display: flex;
	}

	#main-app-window {
		padding: 0 10px;
		margin-top: -220px;
	}

	/* allow scroll on mobile devices */
	body {
		overflow-y: scroll !important;
	}

	#mobile-navigation {
		display: block;
	}

	#intercom-container > div > iframe {
		bottom: 65px !important;
	}

	#main-app-content,
	#navbar {
		margin-left: 0;
	}

	/* add some space, because mobile bottom navigation hides some stuff */
	#main-app-content {
		padding-bottom: 100px;
	}
}

#bottom-mobile-navbar a:hover,
#bottom-mobile-navbar a:focus,
#bottom-mobile-navbar a:active {
	text-decoration: none !important;
}

/* credit-request-table-heading-mobile */

.credit-requests-container-content-mobile,
.mobile-content {
	display: none !important;
}

@media only screen and (max-width: 1060px) {
	.credit-requests-container-content,
	.desktop-content {
		display: none !important;
	}

	.credit-requests-container-content-mobile,
	.mobile-content {
		display: flex !important;
	}

	#credit-request-table-header {
		opacity: 1;
	}
}

/* bootstrap no gutters */
.row.no-gutters {
	margin-right: 0;
	margin-left: 0;
}

.row.no-gutters > [class^='col-'],
.row.no-gutters > [class*=' col-'] {
	padding-right: 0;
	padding-left: 0;
}

.active-company-request-item {
	background: #e8e8e8 !important;
}

#company-requests-wrapper {
	overflow-x: scroll;
}

#navbar-img-wrapper {
	position: absolute;
	width: 100%;
	top: -350px;
	left: -300px;
	min-width: 600px;
	height: auto;
}

#navbar-img {
	opacity: 0.5;
	/* position: absolute; */
	/* width: 120vw; */
	/* background-position: fixed; */
	/* min-width: */
	/* height: auto; */
	z-index: 1;
}

@keyframes spinner {
	to {
		transform: rotate(360deg);
	}
}

.spinner:before {
	content: '';
	box-sizing: border-box;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 20px;
	height: 20px;
	margin-top: -10px;
	margin-left: -10px;
	border-radius: 50%;
	border: 2px solid #ccc;
	border-top-color: #000;
	animation: spinner 0.6s linear infinite;
}

.user-request-info-tooltip:hover {
	opacity: 0.6;
	cursor: pointer;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

img.uploaded-file-img {
	height: 50px;
	width: 50px;
	border-radius: 5px;
	border: 1px solid black;
}

.request-status-icon {
	font-size: 16px !important;
	vertical-align: -3px;
}

.cursor-pointer {
	cursor: pointer !important;
}

.confirmation-method-link {
	color: inherit;
}

.confirmation-method-link:hover {
	text-decoration: none;
	color: inherit;
}

.confirmation-method:hover {
	background: #e4e4e4;
	border-radius: 5px;
	transition: 0.3s;
}

.confirmation-method-logo {
	max-height: 40px;
	width: auto;
}

.confirmation-method-logo-col {
	width: 10%;
	padding: 0px 5px !important;
}
